<template>
	<div class="admin-container">
		<Head />
		<div class="accr_box whole_000 whole_fff">
			<div class="container">
				<div class="accr-box">
					<div class="accr-top flex flex-ac">
<!--						<i class="el-icon-arrow-left"></i>-->
						<p>{{$t('czTxt')}}</p>
						<el-button class="recharge-code"  type="primary" @click="dialogVisible = true">{{$t('recbtn')}}</el-button>
					</div>
					<el-dialog :visible.sync="dialogVisible" width="360px" custom-class="custom-dialog">
						<div slot="title" class="dialog-title"><b>{{$t('recbtn')}}</b></div>
						<div class="dialog-content">
							<div class="transfer-cell">
								<el-input v-model="withdrawCode" :placeholder="$t('recplaceholder')"></el-input>
							</div>
						</div>
						<div slot="footer" class="dialog-footer">
							<el-button type="primary" @click="applyCode">{{$t('submit')}}</el-button>
						</div>
					</el-dialog>
					<el-dialog :visible.sync="dialogVisible2" width="360px" custom-class="custom-dialog">
						<div slot="title" class="dialog-title"><b>{{$t('recbtn')}}</b></div>
						<div class="dialog-content">
							<div class="transfer-cell" style="margin-bottom: 20px;">
								<div class="transfer-cell__hd">{{$t('wtth3')}}</div>
								<el-input v-model="withdrawCodeInfo.coin.unit" disabled></el-input>
							</div>
							<div class="transfer-cell">
								<div class="transfer-cell__hd">{{$t('recth4')}}</div>
								<el-input v-model="withdrawCodeInfo.withdrawAmount" disabled></el-input>
							</div>
						</div>
						<div slot="footer" class="dialog-footer">
							<el-button type="primary" @click="applyRecharge">{{$t('submit')}}</el-button>
						</div>
					</el-dialog>
					<div class="accr-sel">
						<div class="accr_sel">
							<div class="accr_sel_t">{{$t('czTxt2')}}</div>
							<el-select v-model="coin" :placeholder="$t('placeholder3')" @change="coinChange">
								<el-option v-for="(item,index) in options" :key="index" :label="item.name"
									:value="item.name">
								</el-option>
							</el-select>
						</div>
						<div class="accr_sel">
							<div class="accr_sel_t">{{$t('czTxt3')}}</div>
							<el-select v-model="protocolName" :placeholder="$t('placeholder3')"
								@change="protocolChange">
								<el-option v-for="(item,index) in options2" :key="index" :label="item.protocolName"
									:value="item.protocol">
								</el-option>
							</el-select>
						</div>
						<div class="accr_sel">
							<div class="accr_sel_t">{{$t('recth3')}}</div>
							<div v-if="address == ''" class="apply-address">
								<div class="apply-address-text">{{$t('cztip')}}</div>
								<el-button type="primary" @click="getAddress">{{$t('czget')}}</el-button>
							</div>
							<div v-else class="accr_sel_add flex flex-ac">
								<div class="accr_add_p">{{address}}</div>
								<div class="accr_add_icon">
									<img src="../../assets/photo/copy.png" alt="" v-clipboard:copy="address"
										v-clipboard:success="onCopy" v-clipboard:error="onError">
								</div>
								<el-popover placement="right" width="220" trigger="hover">
									<vue-qr :text="address" :margin="0" :size='300'></vue-qr>
									<el-button type="primary" slot="reference" class="accr_code">
										<img src="../../assets/photo/icon7.png" alt="">
									</el-button>
								</el-popover>
							</div>
						</div>
						<div class="accr_cozy">
							<div class="accr_co_t">{{$t('czTxt9')}}</div>
							<div class="accr_co_p">
								<p class="ellipsis">•
									{{$t('czTxt10')}}{{protocol.minrecharge}}{{coin}},{{$t('czTxt10_1')}}
								</p>
								<p class="ellipsis">• {{$t('czTxt11')}}</p>
								<p class="ellipsis">• {{$t('czTxt12')}}</p>
								<p class="ellipsis">• {{$t('czTxt13')}}</p>
								<p class="ellipsis">• {{$t('czTxt14')}}</p>
							</div>
						</div>
					</div>
					<div class="api_flex_table">
						<div class="api_table_tt">{{$t('czTxt15')}}</div>
						<div class="exte-table accr_table exte-box" style="margin: 0;">
							<el-table :data="tableData" style="width: 100%">
								<el-table-column prop="addTime" :label="$t('recth1')">
									<template slot-scope="scope">
										<span>{{ scope.row.addTime | datefmt('YYYY-MM-DD HH:mm:ss') }}</span>
									</template>
								</el-table-column>
								<el-table-column prop="coinName" :label="$t('czTxt2')">
								</el-table-column>
								<el-table-column prop="protocolName" :label="$t('recth2')">
								</el-table-column>
								<el-table-column prop="address" :label="$t('recth3')">
								</el-table-column>
								<el-table-column prop="money" :label="$t('recth4')">
								</el-table-column>
								<el-table-column prop="status" :label="$t('wtth8')">
									<template slot-scope="scope">
										<span v-if="scope.row.status == -1">{{$t('recstatus_0')}}</span>
										<span v-else-if="scope.row.status == 0">{{$t('recstatus_1')}}</span>
										<span v-else-if="scope.row.status == 1">{{$t('recstatus_2')}}</span>
										<span v-else>--</span>
									</template>
								</el-table-column>
								<template slot="empty">
									<img src="../../assets/photo/exte1.png" alt="">
									<p>{{$t('czEmpty')}}</p>
								</template>
							</el-table>
							<el-pagination layout="prev, pager, next" @current-change="handleCurrentChange"
								:current-page.sync="currentPage" :total="total" :background="true"></el-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		rechargeList,
		coinList,
		addressRead,
		addressCreate,
		withdrawcodeInfo,
		withdrawcodeRecharge
	} from '@/api/api/user'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	import vueQr from 'vue-qr'
	export default {
		name: 'Home',
		components: {
			Head,
			Foot,
			vueQr
		},
		data() {
			return {
				options: [],
				coin: '',
				coinextList: [],
				options2: [],
				protocolName: '',
				tableData: [],
				currentPage: 1,
				pageSize: 10,
				total: 10,
				address: '',
				protocol: {},
				withdrawCode: '',
				dialogVisible: false,
				dialogVisible2: false,
				withdrawCodeInfo: {
					coin: {
						unit: ""
					}
				}
			};
		},
		mounted() {
			this.getList()
			this.getCoin()
		},
		methods: {
			getList() {
				rechargeList({
					page: this.currentPage - 1,
					pageSize: this.pageSize
				}).then(res => {
					if (res.code == 0) {
						this.tableData = res.data.content
						this.pageSize = res.data.size
						this.total = res.data.totalElements
					}
				})
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.getList()
			},
			getCoin() {
				coinList().then(res => {
					if (res.code == 0) {
            this.coinextList = res.data.coinextList
            this.options=[];
            res.data.coinList.forEach(item => {
              let isHas = false;
              this.coinextList.forEach(coinext => {
                if (coinext.coinName == item.name && coinext.isRecharge == "IS_TRUE") {
                  isHas = true;
                }
              })
              if (isHas) {
                this.options.push(item)
              }
            })
					}
				})
			},
			coinChange(value) {
				this.options2 = []
				this.coinextList.forEach(item => {
					if (item.coinName == value && item.isRecharge == "IS_TRUE") {
						this.options2.push(item)
					}
				})
				this.protocolName = ''
			},
			protocolChange(value) {
				var arr = this.options2.filter(item => {
					return item.protocol == value
				})
				this.protocol = arr[0]
				addressRead({
					coinprotocol: value
				}).then(res => {
					if (res.data && res.data.address) {
						this.address = res.data.address
					}
				})

			},
			onCopy() {
				this.$message.success("success")
			},
			// 复制失败时的回调函数
			onError() {
				this.$message.error("fail")
			},
			getAddress() {
				if (this.protocolName == '') {
					this.$message.error(this.$t('czplaceholder'))
				} else {
					addressCreate({
						coinprotocol: this.protocolName
					}).then(res => {
						if (res.code == 0) {
							this.address = res.data.address
						} else {
							this.$message.error(res.message);
						}
					})
				}
			},
			applyCode() {
				withdrawcodeInfo({
					withdrawCode: this.withdrawCode
				}).then(res => {
					if (res.code == 0) {
						this.withdrawCodeInfo = res.data;
						this.dialogVisible = false
						this.dialogVisible2 = true
					} else {
						this.$message.error(res.message)
					}
				})
			},
			applyRecharge() {
				withdrawcodeRecharge({
					withdrawCode: this.withdrawCode
				}).then(res => {
					if (res.code == 0) {
						this.dialogVisible2 = false
						this.$message.success(res.message)
					} else {
						this.$message.error(res.message)
					}

				})
			}
		}
	}
</script>
